<template>
    <div>
        <page-title :heading="$t('settings.lang_nav_settings_password') " :subheading="$t('settings.lang_nav_settings_password')" :icon=icon></page-title>
        <div class="app-main__inner">
        <ChangePassword></ChangePassword>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../Layout/Components/PageTitle.vue";
    import ChangePassword from "../../components/settings/ChangePassword.vue";

    export default {
        components: {
            PageTitle,
            ChangePassword
        },

        data: () => ({
            icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
        })
    }
</script>